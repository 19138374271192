import { Injectable, Inject } from "@angular/core";
import { Http, Headers } from "@angular/http";
import { ENV } from "../../environments/environment-variables.token";
import { LoadingComponent } from "../components/loading/loading";
import { environment } from "../../environments/environment";
import { NbAuthJWTToken, NbAuthService } from "@nebular/auth";
import "rxjs/add/operator/map";
import { Observable } from "rxjs/Rx";

/*
 Generated class for the AuthProvider provider.

 See https://angular.io/docs/ts/latest/guide/dependency-injection.html
 for more info on providers and Angular DI.
*/

@Injectable()
export class HttpProvider {
private model: Array<any>;
 user: any;
 token: any;
 count=0;
 baseUrl:any ;
 activeModal: any;
 delay: any;

 constructor(
	public http: Http,
	 private authService: NbAuthService,
	 public loader: LoadingComponent,

	 @Inject(ENV) public ENV: any,
	//  public activeModal: NgbActiveModal,
 ) {
	this.baseUrl = ENV.API_URL;
 }

 get(path:string, auth:boolean = true):any {
	// console.log("get")
	if (!auth) {
	 return this.request(path, "get", false);
	} else {
	 return this.getToken()
		.then(() => {
			// console.log("getToken")
		 return this.request(path, "get", true);
		});
	}
 }

 post(path:string, auth: boolean = true, data: any): any {
	if (!auth) {
	 return this.request(path, "post", false, data);
	} else {
	 return this.getToken()
		.then(() => {
		 return this.request(path, "post", true, data);
		});
	}
 }
 getToken():any {
 	const self:any = this;
	return new Promise((resolve, reject) => {
		this.authService.onTokenChange()
			.subscribe((token: NbAuthJWTToken) => {
				// console.log("superToken",token)
				if (token.getValue()) {

					self.token = token.getValue();
					resolve(self.token);
				}
			});
	});
 }
 unaltorizeRequest(method: string, url: string, data?: any) {
	const requestUrl = environment.API_URL + "/" + url;
	const headers: any = { "Content-Type": "application/json"};
	  console.log("data", data);
	if (method === "get") {
		this.model = [requestUrl, { headers }];
	} else { this.model = [requestUrl, data, { headers }]; }
	console.log("REQUEST URL: ", requestUrl);
	console.log("REQUEST MODEL: ", this.model);
	return this.http[method](...this.model).toPromise();
}

 request(path:string, method:string, auth:boolean, data:any = {}):any {
	const url:string = this.baseUrl + "/" + path;
	const headers:any = new Headers();
	headers.append("Content-Type", "application/json");

	if (auth) {
	 headers.append("Authorization", this.token);
	}

	let params: Array<any>;

	if (method === "post") {
	 params = [url, JSON.stringify(data), {headers}];
	} else {
	 params = [url, {headers}];
	}

	if(this.count<1) { this.showLoader();}
	this.count++;
	return new Promise((resolve, reject) => {
	 this.http[method](...params)
		.map(res => {
		 // workaround handle for API problems
		 return res._body === "" ? {} : res.json();
		}).catch((err) => {
			this.count--;
			if(this.count===0) {setTimeout(()=> { this.hideLoader(); }, 1000);}
			reject(err);
			return Observable.throw(err);
		})
		.subscribe(res => {
			this.count--;
			if(this.count===0) {setTimeout(()=> { this.hideLoader(); }, 1000);}
			resolve(res);
		 // loading.dismiss();
		}, (err) => {
			if(this.count===0) {setTimeout(()=> { this.hideLoader(); }, 1000);}
			reject(err);
		 // loading.dismiss();
		});
	});
 }
	showLoader(): void {
		this.ENV.loading = true;
	}
	hideLoader(): void {
		this.ENV.loading = false;
	}
}






