import { Injectable } from "@angular/core";
import { HttpProvider } from "./http";

@Injectable()
export class CandidateProvider {

    constructor(
        private httpProvider: HttpProvider
    ) {}

    getProfiles(data: object): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpProvider.post("candidate-profile/getProfiles", false, data).then(res => {
                resolve(res);
            })
            .catch(err => reject(err));
        });
    }

    createOrUpdate(data: object): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpProvider.post("candidate-profile/createOrUpdate", true, data).then(res => {
                resolve(res);
            })
            .catch(err => reject(err));
        });
    }

    uploadImage(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpProvider.post("candidate-profile/uploadImage", true, data).then(res => {
                resolve(res);
            })
            .catch(err => reject(err));
        });
    }
}