import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { NbAuthService } from "@nebular/auth";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: NbAuthService, private router: Router) {
  }

  canActivate() {
    // return true;
    return this.authService.isAuthenticated();
    // return this.authService.isAuthenticated()
    //   .do(authenticated => {
    //     if (!authenticated) {
    //       this.router.navigate(['auth/login']);
    //     }
    //   });
    // canActive can return Observable<boolean>, which is exactly what isAuhenticated returns
  }
}
