import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule }   from "@angular/forms";

import { NgxLogoutComponent } from "./logout.component"; // <---

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    NgxLogoutComponent,
    // ... here goes our new components
  ],
  exports:[
    // NgxLoginComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class NgxLogoutModule {
}