import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
// import { IonicPageModule } from "ionic-angular"
import { CommonModule } from "@angular/common";
import { DefaultFormComponent } from "./default-form/default-form";
// import { CustomFormsModule } from "ng2-validation";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Ng2FileInputModule } from "ng2-file-input";
import {NgxMaskModule} from "ngx-mask";
import { DefaultTableComponent } from "./default-table/default-table";
import { LoadingComponent } from "./loading/loading";
import { ColorPickerModule } from "ngx-color-picker";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { BrMasker4Module } from "brmasker4";



@NgModule({
	declarations: [DefaultFormComponent, DefaultTableComponent,LoadingComponent],
	imports: [
		BrMasker4Module,
		// NgxMaskModule.forRoot(),
		NgbModule,
		// CustomFormsModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		// Ng2FileInputModule,
		ColorPickerModule,
		NgMultiSelectDropDownModule.forRoot()

	],
	exports: [DefaultFormComponent, DefaultTableComponent, LoadingComponent],
	schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})

export class ComponentsModule {}
