// import { Component, NgModule, CUSTOM_ELEMENTS_SCHEMA  } from "@angular/core";
// import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
// import { ToasterService, ToasterConfig, Toast, BodyOutputType, ToasterModule  } from "angular2-toaster";
// import "style-loader!angular2-toaster/toaster.css";

// /*
// 	Generated class for the Utils provider.

// 	See https://angular.io/docs/ts/latest/guide/dependency-injection.html
// 	for more info on providers and Angular DI.
// */

// @Component({
// 	selector: "ngx-toaster2",
// 	template: "<toaster-container [toasterconfig]="toastconfig"></toaster-container>",
// })
// @NgModule({
// 	imports: [BrowserAnimationsModule, ToasterModule.forRoot()],
// 	declarations: [
// 	ToastComponent
// 	],
// 	providers: [
// 		ToasterService
// 	],
// 	schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
// })
// export class ToastComponent {
// 	private toasterService: ToasterService;
// 	toastconfig: ToasterConfig;
// 	constructor(
// 		toasterService: ToasterService
// 	) {
// 		console.log("Hello Toast");
// 		this.toasterService = toasterService;
// 	}
	
// 	 showToast(type: string, title: string, body: string): void {

// 		// this.toastconfig = new ToasterConfig({
// 		// 	// positionClass: "toast-top-center",
// 		// 	timeout: 8000,
// 		// 	newestOnTop: true,
// 		// 	tapToDismiss: true,
// 		// 	preventDuplicates: false,
// 		// 	animation: "slideDown",
// 		// 	limit: 3,
// 		// });
// 		const init: any = async ()=> {
			
// 			const toast : Toast = {
// 				type: type,
// 				title: title,
// 				body: body,
// 				showCloseButton: true,
// 				bodyOutputType: BodyOutputType.TrustedHtml,
// 			};
			
// 			this.toasterService.pop(toast);
// 			console.log("toastconfig",this.toasterService.pop(toast));
// 		};
// 		init();
// 	}

// 	clearToasts(): void {
// 		this.toasterService.clear();
// 	}
// }
import {NgModule, Component} from "@angular/core";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToasterModule, ToasterService, BodyOutputType, Toast} from "angular2-toaster";
// import {Root} from "./root.component"

@NgModule({
    imports: [BrowserAnimationsModule, ToasterModule.forRoot()],
    declarations: [ToastComponent],
    bootstrap: [ToastComponent]
})

@Component({
    selector: "ngx-toast2",
    template: `
            <toaster-container></toaster-container>`,
	styles: ["#toast-container > div { opacity:1;; }"]
})

export class ToastComponent {
    private toasterService: ToasterService;

    constructor(toasterService: ToasterService) {
        this.toasterService = toasterService;
    }

	showToast(type: string, title: string, body: string): void {
		const toast : Toast = {
			type: type,
			title: title,
			body: body,
			showCloseButton: true,
			bodyOutputType: BodyOutputType.TrustedHtml,
		};
        this.toasterService.pop(toast);
		console.log("toastconfig",this.toasterService.pop(toast));
    }
}