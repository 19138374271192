import { Component, Input  /*Output, EventEmitter*/ } from "@angular/core";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateFRParserFormatter } from "../../providers/datepicker-format";
import { HttpProvider } from "../../providers/http";

/**
 * Generated class for the DefaultFormComponent component.
 *
 * See https://angular.io/docs/ts/latest/api/core/index/ComponentMetadata-class.html
 * for more info on Angular Components.
 */


@Component({
	selector: "default-form",
	templateUrl: "default-form.html",
	styleUrls: ["./default-form.scss"],
	providers: [{provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter}],
})
export class DefaultFormComponent {

	/*
		- TODO:
			- CREATE ION-SELECT FUNCTIONALITY
			- FIND OR CREATE A FUNCTIONING MASK FOR INPUTS
			- BACK, NEXT AND SUBMIT SHOULD RETURN PROMISES FOR BACKEND VALIDATION

		- Example of how to build the parameters for this component.

		- The *back*, *next* and *submit* are EventEmitter for the parent page. Can perform validations on back-end, for instance, checking for user"s e-mail

		- The *step* attribute should be an integer, depending on which step of the form you want to see. Usually it is 0, but for debug purposes it can be changed
			step = 0

		- The *forms* object is the most important attribute of this component. It should have these attributes:
			- The *title* attribute is the title of that form
				title: "Login Data"
			- The *fields* attribute builds the layout of the form accordingly
				fields: {name, label, icon, type}
				- Declaring explicitly the FormControls is not necessary, however in order to use CustomValidators plugin equalTo, for instance, it is necessary
			- The *builder* attribute receives a FormBuilder object to create the form validation. The attributes provided should match the *fields* attr
				builder: FormBuilder object
			- The *validation_messages* attribute receives an array for each form attribute, and each array should provide the messages to return when there is an error in each validation

		- Full Example:

					const email = new FormControl("", Validators.compose([Validators.required, Validators.email]))
					const confirm_email = new FormControl("", CustomValidators.equalTo(email))
					const password = new FormControl("", Validators.compose([Validators.required, Validators.minLength(6)]))
					const confirm_password = new FormControl("", CustomValidators.equalTo(password))

					const name = new FormControl("", Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)]))
					const phone = new FormControl("", Validators.compose([Validators.required, Validators.pattern(/^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/)]))

					step = 0
					forms = [
						{
							fields: [
								{
									name: "email",
									label: "E-mail",
									icon: "fa-envelope-o",
									type: "email"
								},
								{
									name: "confirm_email",
									label: "Confirmar e-mail",
									icon: "fa-envelope-o",
									type: "email"
								},
							],
							builder: this.formBuilder.group({
								email,
								confirm_email,
							}),
							validation_messages: {
								email: [
									{type: "required", message: "Preencha o campo e-mail."},
									{type: "email", message: "E-mail inválido."}
								],
								confirm_email: [
									{type: "equalTo", message: "Deve ser igual ao campo e-mail."}
								],
							}
						}
					]

					back() {
						this.step--
					}

					next() {
						this.step++
					}

					submit() {

					}
	*/

	@Input() forms: any;
	@Input() step: any;
	@Input() controller: any;
	search = "";
	searchList = "";
	searchItens: any;
	result: any;
	total: any;
	delay: any;
	selectedItem: any;
	constructor(private httpProvider: HttpProvider) {
		console.log("Hello DefaultFormComponent Component");
		this.init();
	}
	init(): any {
		const self: any = this;
		if(this.controller) {
			this.controller.defaultForm = this;
		}else {
			setTimeout(()=> {
				self.init();
			},500);
		}
	}

	getItensBySearch(url:string):any {
		clearTimeout(this.delay);
		this.delay = setTimeout(()=> {
			const data: any = {"find": this.search};
			this.httpProvider.post(url, true, {data}).then((res: any) => {
				console.log("RES: ", res);
				this.searchItens = res.itens;
				this.total = res.total;
			})
			.catch(err => { console.log(err);});
		},500 );
	}
	getByEmailList(url:string, calback:any): any {
		clearTimeout(this.delay);
		this.delay = setTimeout(()=> {
			const data: any = {"find": this.searchList};
			this.httpProvider.post(url, true, {data}).then((res: any) => {
				if(res.itens) {
					calback(res.itens);
				}
			})
			.catch(err => { console.log(err);});
		},500 );
	}
	getImage(item: any):any {
		return item.images[0] ? String(item.images[0].host) + String(item.images[0].thumb) : "assets/images/default-avatar.png";
	}
	selectItem(item:any, callback:any ):any {
		this.selectedItem = item;
		this.result = item._id;
		this.searchItens = undefined;
		if(callback) {callback(item); }
	}
	setValue(event, formB){
		formB.value = event.target.value;
	}

}



