import { Component, Input, OnInit,Inject } from "@angular/core";

import { NbMenuService, NbSidebarService } from "@nebular/theme";
import { UserData } from "../../../@core/data/users";
import { AnalyticsService } from "../../../@core/utils";
import { LayoutService } from "../../../@core/utils";
import { NbAuthJWTToken, NbAuthService } from "@nebular/auth";
import { ENV } from "../../../../environments/environment-variables.token";
@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit {

  @Input() position = "normal";

  user: any;

  userMenu = [
      // { title: 'Profile' },
      { title: "Log out", link: "/auth/logout" },
  ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private userService: UserData,
              private analyticsService: AnalyticsService,
              private layoutService: LayoutService,
              private authService: NbAuthService,
              @Inject(ENV) public ENV: any,
          ) {
  }

  ngOnInit() {
    // this.userService.getUsers()
    //   .subscribe((users: any) => this.user = users.nick);
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.getValue()) {
          this.user = token.getPayload();
          this.user.picture = this.user && this.user.images &&  this.user.images[0]?this.user.images[0].host+this.user.images[0].thumb:"";
          // here we receive a payload from the token and assigne it to our `user` variable
        }

      });
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent("startSearch");
  }
}
