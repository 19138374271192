import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import {
  NbAuthComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from "@nebular/auth";

import { NgxLoginComponent } from "./@theme/auth/login/login.component"; // <---
// import { NbLoginComponent } from "./@theme/auth/login/login.component";
import { NgxLogoutComponent } from "./@theme/auth/logout/logout.component";
import { AuthGuard } from "./auth-guard.service";

const routes: Routes = [
  { path: "pages",
  loadChildren: "app/pages/pages.module#PagesModule",
  canActivate: [AuthGuard],
    },
  {
    path: "auth",
    component: NbAuthComponent,
    children: [
      {
        path: "",
        component: NgxLoginComponent,
      },
      {
        path: "login",
        component: NgxLoginComponent,
      },
      {
        path: "register",
        component: NbRegisterComponent,
      },
      {
        path: "logout",
        component: NgxLogoutComponent,
      },
      {
        path: "forgotPassword",
        component: NbRequestPasswordComponent,
      },
      {
        path: "resetPassword",
        component: NbResetPasswordComponent,
      }
    ],
  },
  { path: "", redirectTo: "auth", pathMatch: "full" },
  { path: "**", redirectTo: "pages" },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config),],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
