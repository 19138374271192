import { Component, Inject } from "@angular/core";
import { NbLogoutComponent, NbAuthResult } from "@nebular/auth";
import { AuthProvider } from "../../../providers/auth";

import { Router } from "@angular/router";

import { NbAuthService, NB_AUTH_TOKENS } from "@nebular/auth";
import { getDeepFromObject } from "@nebular/auth/helpers";
@Component({
  selector: "ngx-logout",
  templateUrl: "./logout.component.html",
})
export class NgxLogoutComponent{
  redirectDelay: number = 0;
  provider: string = "";
  constructor(protected service: NbAuthService,
    @Inject(NB_AUTH_TOKENS) protected config = {},
    protected router: Router) {
    this.redirectDelay = this.getConfigValue("forms.logout.redirectDelay");
    this.provider = this.getConfigValue("forms.logout.provider");
  }
  ngOnInit(): void {
    console.log(this.provider);
    // this.logout(this.provider);
    setTimeout(() => {
      localStorage.clear();
      this.router.navigateByUrl("/");
    }, 1000);


  }
  // logout(provider: string): void {
  //   this.service.logout(this.provider).subscribe((result: NbAuthResult) => {
  //     console.log("xuxa",result)
  //     const redirect = result.getRedirect();
  //     if (redirect) {
  //       setTimeout(() => {
  //         // localStorage.clear();
  //         return this.router.navigateByUrl(redirect);
  //       }, this.redirectDelay);
  //       //
  //     }
  //   });
  // }
  getConfigValue(key: string): any {
    return getDeepFromObject(this.config, key, null);
  }
}
