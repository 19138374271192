import {
  Component,
  Input,
  Output /*Output, EventEmitter*/,
} from "@angular/core";
import { HttpProvider } from "../../providers/http";
import * as moment from "moment";
/**
 * Generated class for the DefaultTable component.
 *
 *
 */

@Component({
  selector: "default-table",
  templateUrl: "default-table.html",
  styleUrls: ["./default-table.scss"],
  providers: [],
})
export class DefaultTableComponent {
  /*
		- TODO:
			- CREATE SEARCH FUNCTIONALITY
			- CREATE TABLE TEMPLATE
			- CALLBACK FOR CHANGES ON PAGINATION

			options: {
				provider: // Server url for filter and list,
				headers: // Table headers for orderBy {"text","value"}
				template: // html template for form itens (users tableItens var for loop),
				perPage: //itens per page
			}
	*/

  @Input() options: any;
  @Input() itens: any;
  @Output() refresh: any;
  search = "";
  sort: any;
  page = 0;
  total = 0;
  perPage = 10;
  pagination: any;
  delay: any;
  paginationLimited = 0;
  additionalFilter: any;

  constructor(private httpProvider: HttpProvider) {
    console.log("Hello DefaultTableComponent Component");
    this.init();
  }

  init(): any {
    /* console.log('refresh',this.itens) */
    const self: any = this;
    if (this.options) {
      this.options.controller.defaultTable = this;
      this.getItens();
    } else {
      setTimeout(() => {
        self.init();
      }, 500);
    }
  }

  public getItens(sort: string = "", page: number = 0): any {
    const self: any = this;
    this.options.getItens = this.getItens;
    const data: any = {
      find: this.search,
      page: page,
      sort: sort,
      perPage: this.options.perPage,
      additionalFilter: this.additionalFilter,
    };
    this.httpProvider
      .post(this.options.url, false, { data })
      .then((res: any) => {
        console.log("res", res);
        if (res.itens != null) {
          this.itens = res.itens;
          this.total = res.total;
          this.pagination = [];
          for (
            var i: number = 0;
            i <= Math.ceil(this.total / this.options.perPage);
            i++
          ) {
            this.pagination.push(i);
          }
        }
      })
      .catch((err) => console.log(err));
  }

  orderSearch(value: any): void {
    var sort: any;
    if (this.sort !== value) {
      this.sort = value;
      sort = value;
    } else {
      this.sort = "";
      sort = "-" + value;
    }
    this.getItens(sort);
  }

  getValues(item: any, properties: string, pipe: string): any {
    var tempItem: any = item;
    const tempProperties: any = properties.split(".");

    if (tempProperties.length < 1) return "";

    tempProperties.forEach((tp) => {
      if (tempItem && tempItem.hasOwnProperty(tp)) tempItem = tempItem[tp];
      else tempItem = "Não informado";
    });
    if (pipe === "date")
      return tempItem != "Não informado"
        ? moment(tempItem).format("DD/MM/YYYY")
        : "-";
    else if (pipe === "images")
      return tempItem
        ? "<img width='50' height='50' src='" +
            tempItem.host +
            tempItem.thumb +
            "'>"
        : "<img width='50' height='50' src='http://www.franzautomoveis.com.br/site/wp-content/plugins/jpro-cars/assets/img/no_image.jpg'>";
    else if (pipe === "expired")
      return moment(tempItem).isBefore(moment()) ? "Expirado" : tempItem;
    // else if(pipe === "customDate") return ( !tempItem || !tempItem.ativacao || !tempItem.duracao) ? "Expirado ou Inesistente": moment(tempItem.ativacao).add(tempItem.duracao,"days").format("DD/MM/YYYY")
    else if (pipe === "customDate")
      return !tempItem || !tempItem.ativacao || !tempItem.duracao
        ? "Inesistente"
        : moment(tempItem.ativacao)
            .add(tempItem.duracao + 1, "days")
            .format("DD/MM/YYYY");
    else if (pipe === "boolean") return tempItem ? "Sim" : "Não";
    else if (pipe === "currency")
      return parseFloat(tempItem).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    else return tempItem;
  }

  getItensBySearch(): any {
    clearTimeout(this.delay);
    this.delay = setTimeout(() => {
      this.getItens();
    }, 1000);
  }

  reset(): any {
    this.search = "";
    this.getItens();
  }
}
