import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { NgxAuthRoutingModule } from "./auth-routing.module";
import { NbAuthModule } from "@nebular/auth";
import {
  NbAlertModule,
  NbButtonModule,
  NbCheckboxModule,
  NbInputModule
} from "@nebular/theme";


import { NgxLoginModule } from "./login/login.module"; // <---
import { NgxLogoutModule } from "./logout/logout.module"; // <---

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbCheckboxModule,
    NgxAuthRoutingModule,
    NgxLoginModule,
    NgxLogoutModule,

    NbAuthModule,
  ],
  declarations: [
    // NgxLoginComponent,
    // ... here goes our new components
  ],
  exports:[

  ]
})
export class NgxAuthModule {
}