import { Injectable } from "@angular/core";


/*
    Generated class for the AuthProvider provider.

    See https://angular.io/docs/ts/latest/guide/dependency-injection.html
    for more info on providers and Angular DI.
*/

@Injectable()
export class PermissionProvider {

    constructor(
    ) { }
    hasPermission(permissao) {
        return new Promise((resolve, reject) => {
            const obj = JSON.parse(localStorage.getItem("user"));
            const authorized = obj.user.role.alias.findIndex(x => {return x.titulo === permissao;});
            if (authorized < 0) {
                reject(false);
            } else {resolve(true);}
        });
    }

    itemShow(permissao) {
        return new Promise((resolve, reject) => {
            const obj = JSON.parse(localStorage.getItem("user"));
            const authorized = obj.user.role.alias.findIndex(x => { return x.titulo === permissao; });
            if (authorized < 0) {
                reject(false);
            } else {resolve(true);}
        });
    }
}
