import { Injectable,Inject } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { PermissionProvider } from "../providers/permission";
// import { Socket } from "ngx-socket-io";
import { ENV } from "../../environments/environment-variables.token";
function _window(): any {
  // return the global native browser window object
  return window;
}
@Injectable()
export class PermissionResolve implements Resolve<any> {

  constructor(
    private permission: PermissionProvider,
    // private socket: Socket,
    @Inject(ENV) public ENV: any,
    ) {
    const win =  this.nativeWindow();
    win.onfocus = ()=>{
      const _user = JSON.parse(localStorage.getItem("user"));
      // this.socket.removeAllListeners();
      // this.socket.emit("apphandshake", _user.user);


    };
  }

  resolve(route: ActivatedRouteSnapshot) {
    const _user = JSON.parse(localStorage.getItem("user"));
    // this.socket.removeAllListeners();
    // this.socket.emit("apphandshake", _user.user);
    this.ENV.online = true;
    return this.permission.hasPermission(route.data.permission);
  }
  nativeWindow(): any {
    return _window();
  }
}