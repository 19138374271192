import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule }   from "@angular/forms";
import { AuthProvider } from "../../../providers/auth";

import { NgxLoginComponent } from "./login.component"; // <---

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    NgxLoginComponent,
    // ... here goes our new components
  ],
  exports:[
    // NgxLoginComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers:[AuthProvider]
})
export class NgxLoginModule {
}