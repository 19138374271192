import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NbAuthComponent } from "@nebular/auth";  // <---
import { NgxLoginComponent } from "./login/login.component"; // <---
import { NgxLogoutComponent } from "./logout/logout.component"; // <---

export const routes: Routes = [
  // .. here goes our components routes
  {
    path: "auth",
    component: NbAuthComponent,  // <---
    children: [
        {
          path: "login",
          component: NgxLoginComponent, // <---
        },
        {
          path: "logout",
          component: NgxLogoutComponent, // <---
        },
      ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NgxAuthRoutingModule {
}
