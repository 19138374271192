import { Injectable } from "@angular/core";
import { HttpProvider } from "./http";
import { AsyncLocalStorage } from "angular-async-local-storage";

/*
	Generated class for the AuthProvider provider.

	See https://angular.io/docs/ts/latest/guide/dependency-injection.html
	for more info on providers and Angular DI.
*/

@Injectable()
export class AuthProvider {

	constructor(
		private httpProvider: HttpProvider,
		protected storage: AsyncLocalStorage
	) {
		console.log("Hello Auth Provider");
	}

	me() {
		return new Promise((resolve, reject) => {
			this.httpProvider.get("auth/protected", true).then(_user => {
				localStorage.setItem("user", JSON.stringify(_user));
				resolve(_user);
			})
			.catch(err => reject(err));
		});
	}

	setPhotoEvent(data) {
		return new Promise((resolve, reject) => {
			this.httpProvider.post("auth/setPhotoEvent", true, data).then(res => {
				resolve(res);
			})
			.catch(err => reject(err));
		});
	}

	removePhotoEvent(data) {
		return new Promise((resolve, reject) => {
			this.httpProvider.post("auth/removePhotoEvent", true, data).then(res => {
				resolve(res);
			})
			.catch(err => reject(err));
		});
	}
}