import { Component, Input, Inject  /*Output, EventEmitter*/ } from "@angular/core";
import { ENV } from "../../../environments/environment-variables.token";
/**
 * Generated class for the DefaultTable component.
 *
 *
 */

@Component({
	selector: "default-loading",
	templateUrl: "loading.html",
	styleUrls: ["./loading.scss"],
	providers: [],
})
export class LoadingComponent {

	/*
		- TODO:
			- CREATE SEARCH FUNCTIONALITY
			- CREATE TABLE TEMPLATE
			- CALLBACK FOR CHANGES ON PAGINATION

			options: {
				provider: // Server url for filter and list,
				headers: // Table headers for orderBy {"text","value"}
				template: // html template for form itens (users tableItens var for loop),
				perPage: //itens per page
			}
	*/

	@Input() message: any;


	show = false;

	constructor(@Inject(ENV) public ENV,) {
		console.log("Hello DefaultTableComponent Component");
		this.init();
	}

	init(): any {

	}
	public showLoader(){
		this.show = true;
	}
	public hideLoader(){
		this.show = false;
	}


}



