import { Component, Inject } from "@angular/core";
import { NbAuthResult } from "@nebular/auth";
import { AuthProvider } from "../../../providers/auth";

import { Router } from "@angular/router";

import { NbAuthService, NB_AUTH_TOKENS } from "@nebular/auth";
import { getDeepFromObject } from "@nebular/auth/helpers";
import { ToastComponent } from "../../../providers/toast";

// import { AsyncLocalStorage } from "angular-async-local-storage";

@Component({
  selector: "ngx-login",
  templateUrl: "./login.component.html",
})
export class NgxLoginComponent {
//  constructor(service: NbAuthService, options: {}, cd: ChangeDetectorRef, router: Router, auth:AuthProvider){
//    super(service,options,cd, router)

//  }
  redirectDelay: number = 0;
	showMessages: any = {};
	provider: string = "";
	errors: string[] = [];
	messages: string[] = [];
	user: any = {};
	grupos: any  = [];
	showGrupos = false;
	selectedUser: any;
	redirect: any;
  submitted: boolean = false;

  constructor(
		protected service: NbAuthService,
		protected auth: AuthProvider,
		@Inject(NB_AUTH_TOKENS) protected config = {},
		protected router: Router,
		private toast: ToastComponent,
		// protected storage: AsyncLocalStorage
	) {
		this.redirectDelay = this.getConfigValue("forms.login.redirectDelay");
		this.showMessages = this.getConfigValue("forms.login.showMessages");
		this.provider = this.getConfigValue("forms.login.provider");
	}


  login(): void {
		this.errors = this.messages = [];
		this.submitted = true;
		// this.showGrupos = false;
		this.grupos =[];
		this.user.email = this.user.email.toLowerCase();
		this.service.authenticate("email", this.user).subscribe((result: NbAuthResult) => {
			this.submitted = false;
			if (result.isSuccess()) {
				this.messages = result.getMessages();
				this.redirect = result.getRedirect();
				this.auth.me().then((user:any) => {
					if(user.user.removed) {
						localStorage.clear();
						return this.toast.showToast("error", "Erro", "Este usuário foi removido.");
					}
					this.selectedUser = user;
					console.log(user.user.permissions);
					this.grupos = user.user.permissions.filter(x=>{ return x.tags.findIndex(y=>y.name === "admin") > -1;});
					// this.showGrupos = true;
					console.log("grup", this.grupos);
					if (this.redirect) {
						const group = this.selectedUser.user.permissions[0].group;
						this.selectedUser.user.role = this.selectedUser.user.permissions[0].permission;
						this.selectedUser.user.tags = this.selectedUser.user.permissions[0].tags;
						localStorage.setItem("user", JSON.stringify({...this.selectedUser}));
						localStorage.setItem("grupo",JSON.stringify(group));
						setTimeout(() => {
							return this.router.navigateByUrl(this.redirect);
						}, this.redirectDelay);
					}
				})
				.catch(err => console.log(err));
				console.log(this.messages);
			} else {
				this.errors = result.getErrors();
			}

		});
  }
  	getConfigValue(key: string): any {
		return getDeepFromObject(this.config, key, null);
	}
	enterGroup(item){
		this.selectedUser.role = item.permission;
		this.selectedUser.tags = item.tags;
		localStorage.setItem("user", JSON.stringify({"user":this.selectedUser}));
		localStorage.setItem("grupo",JSON.stringify(item.group));
		if (this.redirect) {
			setTimeout(() => {
				return this.router.navigateByUrl(this.redirect);
			}, this.redirectDelay);
		}
	}
}

